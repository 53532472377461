import { createRouter, createWebHashHistory} from "vue-router";
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Projects from '../views/Projects.vue';
import Contact from '../views/Contact.vue';
import Jobs from '../views/Jobs.vue';
import Impressum from '../views/Impressum.vue';
import Login from '../views/Login.vue';
import Privacy from '../views/Privacy.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: Impressum
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/jobs',
        name: 'Jobs',
        component: Jobs
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router