<template>
    <div>
        <h1 class="page-headline" :class="mq.current">Login</h1>
        <form class="login-form">
            <input class="form-control" name="user_name" placeholder="Nutzername">
            <input class="form-control" name="user_password" placeholder="Passwort">
            <label><input type="checkbox" value="remember-me">Für zwei Wochen angemeldet bleiben</label>
            <button class="projects-button">
                <i class="fa-solid fa-arrow-right-to-bracket"></i>
                <span>&nbsp;Anmelden</span>
            </button>
        </form>
    </div>
</template>

<script>
import { useMq } from 'vue3-mq';
    export default {
        name: 'About',
        components: {
            
        },
        setup() {
    const mq = useMq();

    return { mq };
  }   
    }
</script>
<style>
    .login-form{
        max-width: 330px;
        margin: auto;
    }
    [type="checkbox"]{
        margin-right: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
</style>