<template>
  <div class="wrapper">
    <div class="container" :class="mq.current">
      <div class="header" :class="mq.current">
        <div class="row title-row" :class="mq.current">
          <div class="title-row-logo">
            <div class="row">
              <router-link to="/">
                <img id="logo" class="logo" src="@/assets/images/logomk.png">
              </router-link>
            </div>
          </div>
          <div class="title-row-label">
            <h1 class="header-brand-label" :class="mq.current">Küchen- und Messebau<br>Harnau</h1>
          </div>
        </div>
        <div class="row">
          <div class="nav-container" :class="mq.current">
            <ul class="navbar">
              <li>
                <router-link to="/">
                  <div class="nav-button">
                    Home
                  </div>
                </router-link>
              </li>
              <li>
                <router-link to="/about">
                  <div class="nav-button">
                    Über uns
                  </div>
                </router-link>
              </li>
              <li>
                <router-link to="/projects">
                  <div class="nav-button">
                    Projekte
                  </div>
                </router-link>
              </li>
              <li>
                <router-link to="/contact">
                  <div class="nav-button">
                    Kontakt
                  </div>
                </router-link>
              </li>
              <li>
                <router-link to="/jobs">
                  <div class="nav-button">
                    Jobs
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="menu-bar" :class="mq.current">
        <div class="menu-bar-element menu-bar-header">
          <router-link to="/jobs">
            <img src="@/assets/images/logomk_xs.png" class="menu-bar-header-image"/>
          </router-link>
          <button class="menu-bar-toggle" @click="toggleMenu()">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <div class="menu-bar-element menu-bar-item-list" :class="{'hidden':!toggleIsClicked}">
          <router-link to="/"><div class="menu-bar-item-list-entry"><span class="menu-bar-link-text">Home</span></div></router-link>
          <router-link to="/about"><div class="menu-bar-item-list-entry">Über Uns</div></router-link>
          <router-link to="/projects"><div class="menu-bar-item-list-entry">Projekte</div></router-link>
          <router-link to="/contact"><div class="menu-bar-item-list-entry">Kontakt</div></router-link>
          <router-link to="/jobs"><div class="menu-bar-item-list-entry">Jobs</div></router-link>
        </div>
      </div>
      <div class="content" :class="mq.current">
        <router-view/>
      </div>
      <div class="footer" :class="mq.current">
        <div class="footer-item">
          <span>© KM Harnau 2023</span>
        </div>
        <div class="footer-item">
          <router-link to="/login" class="link-bar-item">Login</router-link>
          <router-link to="/impressum" class="link-bar-item">Impressum</router-link>
          <router-link to="/privacy" class="link-bar-item">Datenschutzerklärung</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMq } from 'vue3-mq';
export default {
  name:"App",
  data(){
    return{
      toggleIsClicked: false,
    }
  },
  methods:{
    toggleMenu(){
      this.toggleIsClicked = !this.toggleIsClicked;
    },
    onWindowResize(){
      this.toggleIsClicked = false;
    }
  }, 
  setup() {
    const mq = useMq();

    return { mq };
  },
  mounted(){
    window.addEventListener('resize',this.onWindowResize);
  },
  unmounted(){
    window.removeEventListener('resize',this.onWindowResize)
  }

};
</script>
<style>
  html{
    ::-webkit-scrollbar{width:10px}::-webkit-scrollbar-track{background:#ffffff;}::-webkit-scrollbar-thumb{background:#ef7b00;border-radius:5px;}
  }
  .hidden{
    display: none!important;
  }
  .nav-container.middle, .nav-container.large, .nav-container.xlarge
  {
    width: 66.66666666666666%;
    margin-left: 16.666666666666664%;
  }
  .navbar{
    list-style: none;
    margin: 0;
    display: flex;
    padding-inline-start: 0;
    width: 100%;
  }
  .header .navbar>li{
    flex:1;
  }
  .navbar a{
    text-decoration: none;
  }
  .nav-button{
    background-color: #e6e6e6;
    border: 1px solid #d7d7d7;
    border-left: none;
    padding: 19px 15px;
    text-align: center;
    color: #333;
  }
  .nav-button:hover{
    background-color: #ffe6ef;
  }
  .router-link-active .nav-button{
    background-color: #ef7b00;
    color: white;
  }
  .header .navbar li:first-child .nav-button{
    border-left: 1px solid #d7d7d7!important;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  .header .navbar li:last-child .nav-button{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
  .xsmall.header{
    display: none!important;
  }
  .small.header{
    min-height: 240px;
  }
  .header{
    min-height: 265px;
    background-color: rgba(255, 255, 255, 0.8);
    margin-bottom: 60px;
  }
  .menu-bar{
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    min-height: 50px;
    display: flex;
    flex-direction: column;
  }
  .small.menu-bar,.middle.menu-bar,.large.menu-bar,.xlarge.menu-bar{
    display: none;
  }
  .menu-bar-element{
    width: 100%;
    display: flex;
  }
  .menu-bar-header{
    justify-content:space-between;
    height: 50px;
  }
  .menu-bar-header-image{
    padding: 5px 10px;
    height: 100%;
  }
  .menu-bar-item-list{
    flex-direction: column;
  }
  .menu-bar-item-list-entry{
    display: flex;
    width: 100%;
    padding:5px 10px;
    justify-content: center;
    align-items: center;
  }
  .menu-bar-item-list-entry:hover{
    background-color: rgba(255, 255, 255, 0.2);
  }
  .router-link-active .menu-bar-item-list-entry {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .menu-bar-item-list a{
    text-decoration: none;
    color: #333;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .menu-bar-toggle .icon-bar {
    background-color: #888;
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin-bottom: 4px;
  }
  .menu-bar-toggle .icon-bar:last-child {
    margin-bottom: 0;
  }

  .menu-bar-toggle {
    margin-right: 15px;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
  
  .header h1{
    margin-top: 66px;
  }
  .header h1.small{
    font-size: 30px;
    margin-top: 58px!important;
  }
  .header-brand-label.middle,.header-brand-label.large,.header-brand-label.xlarge{
    font-size: 44px;
  }

  .header #logo{
    margin-top: 43px;
  }
  .title-row-logo #logo{
    margin-left: 8.333333333333332%;
    width: 83.33333333333334%;
  }
  .title-row-logo {
    width: 41.66666666666667%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
  .title-row-label{
    width: 50%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
  .content{
    background-color: rgba(255,255,255,0.8);
    padding: 100px;
    margin-bottom: 60px;
  }
  .content.xsmall{
    padding: 10px;
    margin-bottom: 20px;
  }
  .wrapper{
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .container{
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .small.container{
    width: 750px;
  }
  .middle.container{
    width: 970px;
  }
  .large.container{
    max-width: 1030px;
  }
  .xlarge.container{
    width: 1170px;
  }
  .title-row{
    min-height: 205px;
  }
  .small.title-row{
    min-height: 180px!important;
  }
  .container:before{
    content: " ";
    display: table;
  }
  :before,:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
  }
  .footer{
    padding: 9px 15px;
    background-color: rgba(255,255,255,0.8);
    display: flex;
    justify-content: space-between;
  }
  .xsmall.footer{
    flex-direction: column;
    align-items: center;
  }
  .link-bar-item:not(:last-child):after{
    content: " | ";
  }
  body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.428571429;
    color: #333;
    background-color: #fff;
  }
</style>