import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Vue3Mq } from "vue3-mq";

const app = createApp(App);
app.use(Vue3Mq,{
    breakpoints:{
        xsmall:0,
        small:768,
        middle:992,
        large:1000,
        xlarge:1200
    }
});
app.use(router);

app.mount('#app')
