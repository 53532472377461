<template>
    <div>
        <h1 class="page-headline" :class="mq.current">Impressum</h1>
        <p><span>Angaben gemäß § 5 TMG:</span></p>
        <p>
            <span>Rene Harnau</span>
            <br>
            <span>Küchen- und Messebau</span>
            <br>
            <span>Uferstraße 22</span>
            <br>
            <span>04838 Eilenburg</span>
        </p>
        <h2>Kontakt</h2>
        <table>
            <tr>
                <td>
                    <span>Telefon</span>
                </td>
                <td>
                    <a href="tel:+49015124103147">0151/24103147</a>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Email</span>
                </td>
                <td>
                    <a href="mailto:km_harnau@t-online.de">km_harnau@t-online.de</a>
                </td>
            </tr>
        </table>
        <h2>Umsatzsteuer-ID:</h2>
        <p>
            <span>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</span>
            <br>
            <span>237/228/00389</span>
        </p>
        <h2>Angaben zur Berufshaftpflichtversicherung:</h2>
        <p>
            <span>Name und Sitz der Gesellschaft:</span>
            <br><br>
            <span>Aachen Müchener</span>
            <br>
            <span>52054 Aachen</span>
        </p>
        <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
        <p>
            <span>KM Harnau</span>
            <br>
            <span>Rene Harnau</span>
            <br>
            <span>Uferstraße 22</span>
            <br>
            <span>04838 Eilenburg</span>
        </p>
        <p>
            <span>Quelle: <i><a target="blank" rel="nofollow" href="http://www.e-recht24.de">http://www.e-recht24.de</a></i></span>
        </p>
        <h1>Haftungsausschluss (Disclaimer)</h1>
        <p>
            <strong>Haftung für Inhalte</strong>
        </p>
        <p>
            <span>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen 
                Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte 
                oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
                Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
                konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                umgehend entfernen.
            </span>
        </p>
        <p>
            <strong>Haftung für Links</strong>
        </p>
        <p>
            <span>
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir
                für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
                Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </span>
        </p>
        <p>
            <strong>Urheberrecht</strong>
        </p>
        <p>
            <span>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
                der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
                privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.  
            </span>
        </p>
        <p>
            <span>Quelle: </span>
            <i>
                <a target="blank" rel="nofollow" href="http://www.e-recht24.de/muster-disclaimer.html">eRecht24</a>
            </i>
        </p>
    </div>
</template>

<script>
import { useMq } from 'vue3-mq';
    export default {
        name: 'About',
        components: {
            
        },
        setup() {
    const mq = useMq();

    return { mq };
  }   
    }
</script>
<style>
</style>