<template>
    <div>
        <h1 class="page-headline" :class="mq.current">Kontakt</h1>
        <div class="content-groups-container">
            <div class="content-group">
                <address>
                    <strong>KM Harnau</strong>
                    <br>
                    <span>Uferstraße 22</span>
                    <br>
                    <span>04838 Eilenburg</span>
                    <br>
                    <span>
                        M: <a href="tel:+4915124103147">0151 / 24 10 31 47</a>
                    </span>
                </address>
                <address>
                    <strong>Inhaber: Rene Harnau</strong>
                    <br>
                    <a href="mailto:km_harnau@t-online.de">km_harnau@t-online.de</a>
                </address>
            </div>
            <div class="content-group">
                <form @submit.prevent="createPost">
                    <div class="form-group">
                        <label for="inputName">Name</label>
                        <input 
                            type="text"
                            name="contact-name"
                            required 
                            id="inputName" 
                            placeholder="Name" 
                            class="form-control"
                            v-model="formData.name">
                    </div>
                    <div class="form-group">
                        <label for="inputEmail">E-Mail</label>
                        <input 
                            type="email"
                            name="contact-email"
                            required 
                            id="inputEmail" 
                            placeholder="E-Mail" 
                            class="form-control"
                            v-model="formData.email">
                    </div>
                    <div class="form-group">
                        <label for="inputPhone">Telefonnummer (freiwillig)</label>
                        <input 
                            type="tel"
                            name="contact-phone" 
                            id="inputPhone" 
                            placeholder="Telefon" 
                            class="form-control"
                            v-model="formData.tel">
                    </div>
                    <div class="form-group">
                        <label for="textareaMessage">Nachricht</label>
                        <textarea 
                            type="tel"
                            name="contact-message" 
                            id="textareaMessage"
                            required 
                            placeholder="Nachricht" 
                            class="form-control"
                            v-model="formData.message"></textarea>
                    </div>
                    <button class="form-submit-button">Abschicken</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { useMq } from 'vue3-mq';
import axios from 'axios';
    export default {
        name: 'Contact',
        data(){
            return{
                formData:{
                    name:'',
                    email:'',
                    tel:'',
                    message:''
                },
                requestData:{
                    from:{
                        email:'benedikt.gaertner@km-harnau.de',
                        alias:'Benedikt Gaertner'
                    },
                    message:'',
                    to:'gbenedikt@web.de',
                    subject:'Anfrage auf km-harnau.de'
                }
            }
        },
        methods: {
            async createPost() {
                 this.requestData.message = `Anfrage über Webseite\nName:${this.formData.name}\nEmail:${this.formData.email}\nTel:${this.formData.tel}\nNachricht:${this.formData.message}`
                 axios.post('https://api.km-harnau.de/api/mail/sendMail',this.requestData).then(
                    function (response){
                        console.log(response) ;
                    }
                 ).catch(function (error){
                    console.log(error)
                 }) 
                 
            }
        },
        components: {
            
        },
        setup() {
    const mq = useMq();

    return { mq };
  }
    }
</script>

<style>
    .content-groups-container{
        display:flex;
        flex-wrap: wrap;
    }
    .form-control{
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        color: #555;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    textarea.form-control{
        height:auto;
        min-height: 134px;
    }
    label{
        display: inline-block;
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 16px;
    }
    .content-group{
        font-size: 16px;
        flex: 1;
    }
    .page-headline{
        text-align: center;
        size: 50px;
    }
    .page-headline.xsmall{
        font-size: x-small;
    }
    .form-submit-button{

        background-color: #ebebeb;
        border: solid 1px #adadad;
        border-radius: 4px;
        color: #ef7b00;
        padding: 6px 12px;
    }

</style>