<template>
    <div>
        <h1 class="page-headline" :class="mq.current">Herzlich Willkommen</h1>
        <p>
            <span>
                Sie möchten Ihre Küchenaustellung modern und hochwertig präsentieren und sind auf der Suche nach einem verläßlichen Partner dafür?
            </span>
            <br>
            <span>
                <strong>Dann sind Sie hier richtig!</strong>
            </span>
        </p>
        <p>
            <span>
                Auf den folgenden Seiten erhalten Sie weitere Informatinen über uns oder nehmen Sie doch gleich per E-Mail Kontakt mit uns auf.
            </span>
        </p>
        <p>
            <span>KM Harnau</span>
            <br>
            <span>Rene Harnau</span>
        </p>
        <p>
            <router-link to="/projects" class="projects-button">
                Unsere Projekte
            </router-link>
        </p>
    </div>
</template>

<script>
    import { useMq } from 'vue3-mq';
    export default {
        name: 'Home',
        components: {
            
        },
        setup() {
    const mq = useMq();

    return { mq };
  }
    }
</script>

<style>
    .projects-button{
        text-decoration: none;
        color: #ef7b00;
        border: solid #ccc 1px;
        border-radius: 6px;
        background-color: #fff;
        font-size: 21px;
        padding: 14px 24px;
    }
    .page-headline{
        font-size: 50px;
    }

    .xsmall.page-headline{
        font-size: 30px!important;
    }
    
</style>