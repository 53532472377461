<template>
    <div>
        <h1 class="page-headline" :class="mq.current">Einige unserer Projekte</h1>
        <p>
            <span>
                Hier finden Sie bald eine Auswahl unserer Projekte.
            </span>
        </p>
    </div>
</template>

<script>
import { useMq } from 'vue3-mq';
    export default {
        name: 'Projects',
        components: {
            
        },
        setup() {
    const mq = useMq();

    return { mq };
  }
    }
</script>