<template>
    <div>
        <h1 class="page-headline" :class="mq.current">Über uns</h1>
        <p>
            <span>Unser Unternehmen wurde am 07.08.2006 als Einzelunternehmen gegründet und stetig erweitert. Das Team besteht derzeit aus 9 festangestellten Mitarbeitern und zahlreichen SUB-Unternehmen, welche uns bei Bedarf gern tatkräftig unterstützen.</span>
        </p>
        <p>
            <span>Wir verfügen über umfangreiche Erfahrungen und Qualifikationen sowie eine technisch hochwertige Ausstattung damit Ihre Küchenausstellung zum Schmuckstück wird.</span>
        </p>
        <p>
            <span>Unsere Montageteams sind europaweit für mehrere Küchenhersteller unterwegs.</span>
        </p>
        <p>
            <span>
                <strong>Gern übernehmen wir auch die Montage Ihrer Küchenausstellungen!</strong>
            </span>
        </p>
    </div>
</template>

<script>
import { useMq } from 'vue3-mq';
    export default {
        name: 'About',
        components: {
            
        },
        setup() {
    const mq = useMq();

    return { mq };
  }   
    }
</script>
<style>
</style>