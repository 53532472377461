<template>
    <div>
        <h1 class="page-headline" :class="mq.current">Datenschutzerklärung</h1>
        <p>
            <strong>Datenschutz</strong>
        </p>
        <p>
            <span>
                Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten
                personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit
                möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            </span>
        </p>
        <p>
            <span>
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
            </span>
        </p>
        <p>
            <span>
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
                angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
                ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
            </span>
        </p>
        <p class="text-left">
            <i>
                <span>Quelle: </span>
                <a rel="nofollow" href="http://www.e-recht24.de/muster-datenschutzerklaerung.html" target="_blank">eRecht24</a>
            </i>
		</p>
    </div>
</template>

<script>
import { useMq } from 'vue3-mq';
    export default {
        name: 'About',
        components: {
            
        },
        setup() {
    const mq = useMq();

    return { mq };
  }   
    }
</script>
<style>
</style>